@import "./Mixin.sass"
.location
  display: flex
  align-items: center
  justify-content: space-around
  flex-wrap: wrap
  background-color: #E0DED5
  height: 80vh
  h2
    margin-bottom: 30px
    font-family: 'Monumental'
    color: #402021
    font-size: 26px
    text-align: center
    letter-spacing: 2px
    width: 100%
    @include mobile
      font-size: 20px
      padding: 60px 20px 0px 20px
      margin: 0
      text-align: center
  img
    width: 100%

  .icons-wrapper
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 1rem
    padding: 0rem 2rem
    flex: 1
    z-index: 2
    width: 40%
    background-color: #E0DED5
    @include mobile
      width: 100%
      flex-direction: column
      align-items: center
      margin: 0
      margin-bottom: 4rem
    .card
      z-index: 2
      display: flex
      flex-direction: column
      margin: 2rem 0rem
      position: relative
      justify-content: start
      align-items: center
      width: 50%
      @media (max-width: 1366px)
        margin: 1rem 0rem
      @include mobile
        width: 100%
        flex-direction: column
        justify-content: center
        align-items: center
        padding: 0
      p
        text-align: center
        text-wrap: wrap
        argin-bottom: 60px
        font-family: "Gotham"
        font-weight: bolder
        color: #402021
        font-size: 1.6rem
        letter-spacing: 1px
        @media (max-width: 1366px)
          font-size: 1.3rem
        @include mobile
          font-size: 1rem
      img
        width: 55px
        filter: invert(1) sepia(1) saturate(1000) hue-rotate(290deg) brightness(0.3)
        z-index: -1
        margin-right: .5rem
        @media (max-width: 1366px)
          width: 45px

  iframe
    z-index: 1
    border: 0
    width: 60%
    height: 100%
    @include mobile
      width: 100%
      height: 50%
