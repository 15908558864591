@import "./Mixin.sass"
.form
  display: flex
  flex-direction: column
  justify-content: space-between
  align-items: center
  h2
    margin-bottom: 30px
    font-size: 22px
    font-family: "MonumentalLight"
    width: 60%
    text-align: left
    color: #402021
    @include mobile
      font-size: 12px
      width: 100%
  .input-wrapper
    margin-bottom: 10px
    width: 300px
    display: flex
    justify-content: flex-start
    input
      background: #E7E6E1
      width: 100%
      height: 40px
      border: none
      font-size: 11px
      padding: 0 20px
    input[type="checkbox"]
      height: 15px
      width: auto
    label
      font-size: 11px
      font-family: 'MonumentalLight'
      color: #402021
      margin-bottom: 5px
      display: block
  .button
    margin-top: 20px
    background: #402021
    color: white
    border: none
    border-radius: 10px
    font-size: 17px
    padding: 12px 40px
    font-family: 'MonumentalLight'
    @include mobile
      font-size: 12px