@import "./Mixin.sass"
*
  margin: 0
  padding: 0
  box-sizing: border-box
.Hero
  background-size: 100% auto
  background-position: top
  display: flex
  justify-content: space-evenly
  align-items: center
  position: relative
  height: 100vh
  padding: 120px 40px 30px 40px

  @include mobile
    flex-direction: column-reverse
    background-size: auto 100%
    height: 100vh
    justify-content: start
    min-height: auto
    padding: 120px 0 0 0
    background: #F9F6F3

  @include tablet
    flex-direction: column-reverse
    background-size: auto 100%
    height: auto
    min-height: auto
    padding: 0
    background: #F9F6F3
    min-height: 100vh
    max-height: 100vh

  .text
    padding: 40px 0
    background: #402021
    margin-right: auto
    width: 50%
    height: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    @include mobile
      width: 100%
      padding: 20px
      border-radius: 0
      margin-right: 0
      flex-grow: 1
      position: relative
      justify-content: flex-start
    @include tablet
      width: 100%
      padding: 20px
      border-radius: 0
      margin-right: 0
      flex-grow: 1
      position: relative

  .background-image
    width: 100%
    height: 100%
    position: relative
    display: flex
    flex-direction: column
    justify-content: flex-end
    @include mobile
      width: 100%
      height: 100%
      display: flex
    @include tablet
      width: 100%
      height: fit-content
      display: flex
    .ico-mobile
      display: none
      position: absolute
      bottom: 18%
      left: 50%
      transform: translateX(-50%)
      filter: invert(1) drop-shadow(0px 0px 10px rgba(0,0,0,0.2))
      width: 50px
      animation: swipeUp 2s infinite
      @include mobile
        display: block
        z-index: 2

    .overlayer
      position: absolute
      bottom: 0
      left: 0
      width: 100%
      height: 80%
      background: linear-gradient(to right, transparentize(black, 0.3) 10%, transparent)
      @include mobile
        background: linear-gradient(to top, transparentize(black, 0.3) 10%, transparent)
        z-index: 1

    .background
      width: 100%
      height: 80%
      object-fit: cover
      object-position: center
      display: flex
      &.mobile
        display: none
        height: 100%
        mask: linear-gradient(to bottom, transparent 0%, white 50%)
        @inclide mobile
          display: block
      &.desktop
        display: block
        @include mobile
          display: none

    .afranio
      position: absolute
      top: -55px
      left: 0
      right: 0
      margin: 0 auto
      width: 48%
      @include mobile
        top: -60px
        width: 64%

    .banner
      position: absolute
      top: 50%
      left: 50%
      transform: translateX(-50%)
      z-index: 999
      @include mobile
        top: 30%
      h1
        color: #E0DED5
        background: transparent
        font-weight: bold
        font-family: unset
        font-size: 5rem
        position: relative
        top: 45%
        left: 50%
        transform: translateX(-50%)
        z-index: 999
        text-transform: uppercase
        text-wrap: nowrap
        margin-bottom: 3.5rem
        @include mobile
          font-size: 2.5rem
          width: 100%
          top: 35%
        &::after
          content: ''
          width: 25%
          background-color: #E0DED5
          height: 3px
          position: absolute
          bottom: -20px
          left: 50%
          transform: translateX(-50%)
      p
        font-family: "Gotham"
        position: relative
        top: 67%
        bottom: 0
        font-size: 30px
        width: 100%
        color: white
        text-align: center
        left: 50%
        transform: translate(-50%, -50%)
        height: fit-content
        line-height: 1.3
        z-index: 1
        strong
          font-family: "GothamBold"
        @include mobile
          left: 50%
          top: 50%
          transform: translate(-50%,-50%)
          font-size: 20px
          width: 80%
          text-align: center
          z-index: 2

  .bsd
    position: absolute
    top: 70px
    right: 40px
    z-index: 10
    width: 40px
    @include mobile
      top: 52px
      width: 30px
      right: 20px

  .menu-icon
    position: absolute
    cursor: pointer
    top: 70px
    left: 40px
    width: 80px
    @include mobile
      width: 60px
      left: 20px
      top: 50px

  .desktop
    @include mobile
      display: none !important
    @include tablet
      display: none !important
  .mobile
    display: none !important
    @include mobile
      display: block !important
    @include tablet
      display: block !important

@keyframes swipeUp
  0%
    transform: translateY(-90px) translateX(-50%)
  30%
    transform: translateY(0px) translateX(-50%)
  100%
    transform: translateY(-90px) translateX(-50%)
