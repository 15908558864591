body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "UrwRegular";
  src: local("UrwRegular"), 
  url("./assets/fonts/URWClassico-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: 'UrwBold';
  src: local("UrwBold"), url("./assets/fonts/URWClassico-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: 'UrwItalic';
  src: local("UrwItalic"), url("./assets/fonts/URWClassico-Italic.ttf");
  font-weight: normal;
}

@font-face {
  font-family: 'Monumental';
  src: local("Monumental"), url("./assets/fonts/MonumentExtended-Regular.otf");
  font-weight: 300;
}

@font-face {
  font-family: 'MonumentalLight';
  src: local("MonumentalLight"), url("./assets/fonts/MonumentExtended-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: 'MonumentalBold';
  src: local("MonumentalBold"), url("./assets/fonts/MonumentExtended-Ultrabold.otf");
  font-weight: bold;
}

@font-face {
  font-family: 'Gotham';
  src: local("Gotham"), url("./assets/fonts/Gotham-Font/Gotham-Light.otf");
  font-weight: 400;
}

@font-face {
  font-family: 'GothamBold';
  src: local("GothamBold"), url("./assets/fonts/Gotham-Font/Gotham-Bold.otf");
  font-weight: 400;
}

