@import "./Mixin.sass"
.datasheet
  background: #E0DED5
  display: flex
  flex-direction: column
  align-items: center
  padding: 90px 60px
  @include mobile
    padding: 60px 30px
  .header
    border-bottom: 1px solid #402021
    width: 60%
    padding-bottom: 30px
    @include mobile
      width: 100%
    h2
      color: #402021
      font-family: "MonumentalLight"
      text-transform: uppercase
      @include mobile
        font-size: 15px

  .content
    display: flex
    // justify-content: center
    width: 60%
    @include mobile
      width: auto
    &.last
      border-top: 1px solid #402021
    .content-pack
      display: flex
      flex-direction: row
      border-right: 1px solid #402021
      border-bottom: 1px solid #402021
      justify-content: center
      align-items: center
      padding: 20px
      flex-grow: 1
      @include mobile
        padding: 10px
      &.last
        border-right: none
      // &.corner
      //   width: 34.6%
      //   @include mobile
      //     width: 40%
      h3
        color: #402021
        font-size: 70px
        font-family: "Monumental"
        text-align: left
        margin-right: 1rem
        @include mobile
          font-size: 30px
      p
        color: #402021
        font-size: 25px
        font-family: "MonumentalLight"
        text-align: left
        @include mobile
          font-size: 12px

    .inner-content
      display: flex
      flex-direction: column
      justify-content: center
      flex-grow: 2
      padding: 20px
      p
        color: #402021
        font-size: 25px
        font-family: "MonumentalLight"
        text-align: left
        padding: 20px 0
        @include mobile
          font-size: 12px
          padding: 8px 0
        &.first
          border-bottom: 1px solid #402021
