@import "./Mixin.sass"
#procurando-mais
    width: 100%
    min-height: 100vh
    height: auto
    display: flex
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-evenly
    background-color: #E0DED5
    padding: 2rem 4rem
    @media (max-width: 1000px)
        // flex-direction: column
        height: auto
        padding: 0rem 0rem

    .sub-title
        color: #402021
        font-family: "Gotham"
        font-weight: 400
        text-wrap: balance
        width: 100%
        font-size: larger
        @include mobile
            margin-top: 2rem

    .title
        margin-top: 2rem
        display: flex
        width: 100%
        justify-content: center
        align-items: center
        gap: 2rem
        flex-wrap: wrap
        @media (max-width: 1000px)
            flex-direction: column
            align-items: center
        img
            width: 300px
            object-fit: contain
        h1
            color: #402021
            font-family: "Gotham"
            font-size: 2rem
            font-weight: 400
        div
            margin: 0 1em 0 1em
            h2
                font-size: 30px
                color: #402021
                font-family: "Monumental"
                text-transform: uppercase
                overflow: hidden
                white-space: nowrap
                margin: .2rem 0 0 0
                letter-spacing: .15em
                animation: typing 3s steps(13, end) infinite

    .container
        width: 70%
        display: flex
        flex-direction: row
        justify-content: space-around
        margin: 2rem 0 2rem 0
        @media (max-width: 1250px)
            width: 90%
        @media (max-width: 1000px)
            flex-direction: column
        .carrosel
            // width: 50%
            height: 600px
            overflow: hidden
            // border-radius: 20px
            @include mobile
                width: 100%
                border-radius: 0
                margin-bottom: 2rem

            div
                width: 100%
                border: 0
                video
                    object-fit: contain

            .swiper
                height: 100%
                width: 100%
                .swiper-button-next
                    color: white
                .swiper-button-prev
                    color: white
                .swiper-slide
                    img
                        width: 100%
                        height: 100%
                        object-fit: cover

        .content
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            max-width: 500px
            @include mobile
                margin-bottom: 2rem

            img
                width: 70%
                @include mobile
                    margin-top: 2rem
            p
                color: #402021
                font-family: "Gotham"
                font-weight: 400
                margin-top: 2rem
                text-wrap: balance
                font-size: 1.2rem
                margin-bottom: 1.2rem
            ul
                display: flex
                flex-direction: column
                align-items: center
                width: 70%
                text-align: center
                list-style-type: none
                a
                    text-decoration: unset
                    width: 100%
                li
                    color: #402021
                    font-family: "Gotham"
                    font-weight: 400
                    margin-top: 1rem
                    text-wrap: balance
                    border: 1px solid #402021
                    width: 100%
                    padding: 1rem
                    transition: all 0.2s ease-in-out
                    &:hover
                        background-color: #402021
                        color: #E0DED5

            .saibamais
                margin: 2rem
                padding: .5rem 2rem
                background-color: #402021
                text-decoration: none
                border: 1px solid #402021
                border-radius: 5px
                color: white
                transition: all 0.2s ease
                &:hover
                    background-color: transparent
                    color: #402021

@keyframes typing
    from
        width: 0
    50%
        width: 100%
/* The typewriter cursor effect */
