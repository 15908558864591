$mobile-width: 430px
$tablet-width: 768px
$laptopS-width: 1440px
$laptop-width: 1900px

#timeline-mozak
    display: flex
    flex-direction: row
    gap: 2rem
    padding: 0 10%
    position: relative
    margin: 6rem 0rem 6rem 0rem
    @media (max-width: $mobile-width)
        padding: 0 0
        margin-top: 0px
        margin-bottom: 2rem
        flex-direction: column
    .content
        display: flex
        flex-direction: column
        justify-content: center
        align-items: start
        padding: 0 4rem
        width: 100%
        height: auto
        text-align: start
        @media (max-width: $mobile-width)
            padding: 0 1rem
            width: auto
            text-align: center
            align-items: center
        h1
            margin-top: 2rem
            margin-bottom: 19px
            font-size: 1.2rem
            font-weight: bold
            color: #282828
            text-decoration: underline
        p
            font-size: 1.2rem
            font-weight: 100
            margin-top: .5rem
            margin-bottom: 19px
            width: auto
            @media (max-width: $laptopS-width)
                width: auto

        a
            margin-top: .5rem
            border-radius: 200px
            background-color: rgba(50,50,50)
            padding: .5rem 1rem
            color: white
            text-decoration: unset
            transition: all 0.2s ease-in-out
            &:hover
                background-color: rgba(0,0,0)

    .comemorativa
        width: 50%
        @media (max-width: $laptopS-width)
            width: 70%

    .swiper
        width: 100%
        aspect-ratio: 1/1
        border-radius: 5px 5px 50px 5px
        @media (max-width: $mobile-width)
            border-radius: 0px
        .swiper-button-prev
            color: white
        .swiper-button-next
            color: white
        .swiper-wrapper
            .swiper-slide
                width: 100%
                height: 100%
                img
                    width: 100%
                    height: 100%
                    object-fit: cover
