@import "./Mixin.sass"
.footer-section
  padding-top: 2rem
  background: #FAF8F5
  height: 180px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  .text
    margin-bottom: 30px
    font-size: 24px
    display: flex
    width: 50%
    gap: 20px
    align-items: center
    flex-direction: column
    @include mobile
      margin-bottom: 0
      width: 100%
    img
      width: auto
      height: 30%
      @include mobile
        height: 20%
        width: auto
    p
      color: #402021
      @include mobile
        font-size: 18px
    span
      color: #402021
      font-size: .8rem