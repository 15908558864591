@import "./Mixin.sass"
.menu
  position: fixed
  display: flex
  flex-direction: column
  background: white
  z-index: 99
  height: 100vh
  width: 20%
  left: -30%
  align-items: flex-start
  padding: 20px 40px
  transition: left 0.3s ease-in-out
  &.active
    left: 0
  @include mobile
    width: 70%
    left: -100%
    &.active
      left: 0
  img
    width: 80px
    margin-bottom: 20px
    cursor: pointer
  span
    cursor: pointer
    padding: 20px 0