@import "./Mixin.sass"

.cta
  position: fixed
  bottom: 30px
  right: 30px
  display: flex
  flex-direction: column
  z-index: 999
  @include mobile
    bottom: 100px
    right: 10px
  a
    margin-top: 10px
    img
      @include mobile
        width: 40px