@import "./Mixin.sass"
.people
  background: #3B1F1F
  min-height: 100vh
  padding: 80px
  @include mobile
    height: auto
    padding: 40px 20px
  .header
    text-align: left
    margin-bottom: 80px
    @include mobile
      margin-bottom: 30px
    h2
      color: #E0DED5
      font-family: 'MonumentalLight'
      font-size: 22px
      margin-bottom: 30px
    h3
      color: #916C5A
      font-family: 'MonumentalLight'
      font-size: 25px
      width: 40%
      @include mobile
        display: none


  .body
    section
      display: flex
      justify-content: space-between
      margin-bottom: 80px
      @include mobile
        flex-direction: column
        margin-bottom: 0
      .image
        max-width: 50%
        display: flex
        justify-content: flex-start
        align-items: center
        @include mobile
          max-width: 100%
          width: 100%
          margin-bottom: 40px
        img
          max-width: 100% 
          width: 40% 
          @include mobile
            width: 40%   
        div
          margin-left: 30px
          h2
            color: #916C5A
            text-align: left
            font-size: 25px
            font-family: 'MonumentalLight'
            width: 40%
            margin-bottom: 10px
            text-transform: uppercase
            @include mobile
              font-size: 15px
              width: 100%
          h3
            color: white
            text-align: left
            font-family: 'MonumentalLight'
            width: 60%
            font-size: 15px
            text-transform: uppercase
            @include mobile
              font-size: 10px
              width: 80%

  .footer
    position: relative
    display: flex
    justify-content: center
    img
      position: absolute
      top: 0px
      left: -80px
      width: 50px
      @include mobile
        width: 20px
        left: -30px
        top: 20px
    p.description
      position: relative
      font-family: 'MonumentalLight'
      color: #916C5A
      width: 60%
      text-align: left
      font-size: 25px
      margin-left: 100px
      padding-top: 40px
      @include mobile
        font-size: 9px
        margin-left: 30px
        width: 90%