@import "./Mixin.sass"
.video
  padding: 60px
  @include mobile
    padding: 0
  h2
    margin-bottom: 60px
    font-family: 'MonumentalLight'
    color: #402021
    font-size: 25px
    letter-spacing: 2px
    @include mobile
      font-size: 12px
      padding: 0 20px
      text-transform: uppercase
      font-size: 20px
      margin-top: 60px

  iframe
    @include mobile
      width: 100%
