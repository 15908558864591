@import "./Mixin.sass"
.perfect
  display: flex
  height: 100vh
  position: relative
  @include mobile
    flex-direction: column
    height: auto
  h2.mobile
    display: none
    @include mobile
      display: block
      color: #402021
      font-family: 'MonumentalLight'
      text-transform: uppercase
      order: 1
      font-size: 20px
      padding: 30px 20px

  .text
    width: 50%
    padding: 100px 120px 80px 120px
    display: flex
    flex-direction: column
    @include mobile
      width: 100%
      padding: 20px
      order: 3
      text-align: center
    h2
      font-family: 'MonumentalLight'
      text-align: left
      text-transform: uppercase
      color: #402021
      margin-bottom: 40px
      @include mobile
        display: none
    h3
      margin-bottom: 30px
      line-height: 1.6
      text-align: left
      width: 80%
      font-size: 22px
      font-family: 'Gotham'
      font-weight: 100
      color: #3B1F1F
      @include mobile
        font-size: 16px
        text-align: center
        width: 100%


  .image
    display: flex
    justify-content: center
    padding: 60px
    width: 50%
    @include mobile
      width: 100%
      padding: 0
      height: auto
      order: 2
      img
        width: 100%