@import "./Mixin.sass"
.policies
  position: fixed
  bottom: 0
  width: 100%
  height: auto
  display: flex
  background: #E0DED5
  padding: 20px 80px
  align-items: center
  z-index: 999
  @include mobile
    padding: 20px
    flex-direction: column
  &.hide
    display: none
  .text
    width: 60%
    @include mobile
      width: 100%
      margin-bottom: 20px

    p
      color: #402021
      font-family: 'GothamBold'
      text-align: left
      font-size: 15px
      @include mobile
        font-size: 10px
  .action
    width: 40%
    @include mobile
      width: 100%
    button
      text-transform: uppercase
      cursor: pointer
      padding: 10px 40px
      background: #402021
      border-radius: 8px
      border: none
      font-family: 'GothamBold'
      color: white
      font-weight: bold
      @include mobile
        width: 100%
        padding: 10px
        font-size: 10px
