$mobile-width: 430px
$tablet-width: 768px

@mixin mobile
  @media (max-width: #{$mobile-width}) 
    @content

  
@mixin tablet 
  @media (min-width: #{$mobile-width+1}) and (max-width: #{$tablet-width}) 
    @content

