@import "./Mixin.sass"
.photos
  display: flex
  padding: 40px
  justify-content: space-between
  @include mobile
    flex-direction: column
    padding: 0
  .text
    width: 40%
    display: flex
    justify-content: center
    align-items: center
    @include mobile
      width: 100%
      padding: 30px 30px 0 30px
    h2
      font-family: 'MonumentalLight'
      font-size: 22px
      color: #3B1F1F
      width: 60%
      text-align: left
      @include mobile
        font-size: 20px
        width: 100%
        text-align: center

  .photos-wrapper
    width: 50%
    position: relative
    @include mobile
      width: 100%
      padding: 30px
    .carroussel
      width: 100%
    .right
      position: absolute
      right: 20px
      top: 0
      bottom: 0
      margin: auto 0
      width: 40px
      cursor: pointer
      @include mobile
        right: 40px
    .left
      position: absolute
      left: 20px
      top: 0
      bottom: 0
      margin: auto 0
      width: 40px
      cursor: pointer
      @include mobile
        left: 40px