@import "./Mixin.sass"
.book
  display: flex
  justify-content: space-evenly
  padding: 60px
  @include mobile
    flex-direction: column
    padding: 30px
  .image
    width: 50%
    display: flex
    flex-direction: column
    align-items: flex-start
    @include mobile
      width: 100%
      margin-bottom: 30px
    h2
      color: #402021
      font-family: 'MonumentalLight'
      font-size: 20px
      text-align: left
      margin-bottom: 30px
      @include mobile
        font-size: 12px
        text-align: center
        margin-bottom: 30px
    img
      width: 80%
      margin-bottom: 30px
      @include mobile
        width: 100%
    button
      background: #402021
      color: white
      border-radius: 10px
      border: none
      padding: 12px 40px
      font-size: 17px
      font-family: 'MonumentalLight'
      @include mobile
        font-size: 12px
        width: 100%
  .form-wrapper
    display: flex
    margin: 0
    width: 50%
    @include mobile
      width: 100%
      margin-top: 50px
