@import "./Mixin.sass"
.form-section
  padding: 60px 0
  @include mobile
    padding: 40px 15px
  .header
    margin-bottom: 30px
    display: flex
    justify-content: center
    @include mobile
      margin-bottom: 10px
    h2
      color: #402021
      font-family: 'Gotham'
      width: 100%
      @include mobile
        width: 100%
        text-align: center
  .body
    display: flex
    justify-content: center
    @include mobile
      flex-direction: column
      align-items: center
      padding: 30px
    input
      height: 40px
      width: 300px
      background: #ded8cf
      border-radius: 3px
      border: none
      margin-right: 15px
      padding: 0 10px
      @include mobile
        width: 100%
        margin-bottom: 15px
    button
      background: #402021
      border-radius: 8px
      color: white
      border: none
      padding: 10px 40px
  
  .footer
    margin-top: 15px
    label
      margin-left: 10px
      color: #402021
      font-family: 'Gotham'
