@import "./Mixin.sass"
.sea
  display: flex
  justify-content: space-between
  @include mobile
    flex-direction: column
  .text
    width: 50%
    background: #E0DED5
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    @include mobile
      width: 100%
      padding: 60px 30px
    h2
      color: #3B1F1F
      width: 60%
      text-align: left
      margin-bottom: 40px
      font-family: 'MonumentalLight'
      line-height: 1.6
      @include mobile
        width: 100%
        font-size: 12px
        margin-bottom: 20px
        text-align: center
        line-height: 2.2
  .image
    width: 50%
    display: flex
    @include mobile
      width: 100%
    img
      width: 100%