@import "./Mixin.sass"
.arch
  display: flex
  height: 110vh
  flex-direction: column
  margin-bottom: 130px
  @include mobile
    height: auto
    margin-bottom: 40px
  .header
    padding: 50px 0
    @include mobile
      padding: 30px 20px
    h2
      font-family: 'MonumentalLight'
      font-size: 25px
      color: #3B1F1F
      text-transform: uppercase
      @include mobile
        font-size: 20px

  .body
    display: flex
    height: 100%
    @include mobile
      flex-direction: column
    .image
      padding: 30px
      display: flex
      justify-content: center
      width: 60%
      position: relative
      @include mobile
        padding: 80px 30px 30px 30px
        width: 100%
      .swiper
        width: 100%
        .swiper-slide
          img
            width: 100%
            height: 100%
            object-fit: contain
        .swiper-button-next
          color: #3B1F1F
          @include mobile
            color: white
        .swiper-button-prev
          color: #3B1F1F
          @include mobile
            color: white
    .text
      display: flex
      flex-direction: column
      justify-content: center
      align-items: center
      width: 40%
      @include mobile
        width: 100%
        padding: 30px
      p
        width: 80%
        text-align: left
        line-height: 1.6
        font-size: 25px
        font-family: "Gotham"
        color: #3B1F1F
        margin-bottom: 20px
        @include mobile
          font-size: 16px
          width: 100%
          text-align: center
