@import "./Mixin.sass"
.design
  display: flex
  padding: 40px
  justify-content: space-between
  @include mobile
    flex-direction: column-reverse
    padding: 0

  .photos-wrapper
    width: 50%
    position: relative
    @include mobile
      width: 100%
      padding: 30px
    .carroussel
      width: 100%
    .right
      position: absolute
      right: 20px
      top: 0
      bottom: 0
      margin: auto 0
      width: 40px
      cursor: pointer
      @include mobile
        right: 40px
    .left
      position: absolute
      left: 20px
      top: 0
      bottom: 0
      margin: auto 0
      width: 40px
      cursor: pointer
      @include mobile
        left: 40px

  .text
    width: 40%
    display: flex
    justify-content: center
    align-items: center
    @include mobile
      width: 100%
      padding: 30px
    h2
      font-family: 'MonumentalLight'
      font-size: 25px
      color: #3B1F1F
      width: 90%
      text-align: left
      line-height: 1.6
      @include mobile
        font-size: 12px
        text-align: center
